/* Reusable CSS classes */
.section {
  padding: 2rem 0 1rem;

  &__title {
    color: var(--text-color);
    font-size: var(--h2-font-size);
    text-align: center;
    margin-bottom: var(--mb-1);
  }
}

.svg {
  &__img {
    width: 350px;
    height: auto;
    justify-self: center;
  }
  &__color{
    fill: var(--primary-color);
  }
  &__blob{
    fill: var(--primary-color-light);
}
}

/* Layout */
.container {
  max-width: 968px;
  margin: 0 var(--mb-1-5) 0 var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}