.scrollup {
    position: fixed;
    background-color: var(--primary-color);
    right: 1.5rem;
    bottom: -10rem;
    display: flex;
    padding: 0.5rem;
    border-radius: 0.25rem;
    z-index: var(--z-tooltip);
    opacity: 0.8;
    transition: var(--trans-length);

    &:hover {
        background-color: var(--secondary-color);
        opacity: 1;
    }

    &__icon {
        font-size: var(--normal-font-size);
        color: var(--title-color);
    }
}

/* Show Scroll Up Icon upon scrolling */
.show__scrollup {
    bottom: 3rem;
}

/* Custom Scroll Bar */
::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 0.3rem;
    background-color: var(--scroll-bar-color);

    &-thumb {
        background-color: var(--scroll-thumb-color);
        border-radius: 0.3rem;

        &:hover {
            background-color: var(--text-color-light);
        }
    }
}