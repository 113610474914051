.pricing {
    &__container {
        padding: 1rem 0;
        row-gap: 2rem;
    }

    &__data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        gap: 1rem;
        background-color: var(--container-color);
        box-shadow: 0 2px 6px hsla(var(--hue), 100%, 15%, 0.2);
        border-radius: 1rem;
        padding: 2rem 1.5rem;
    }

    &__subtitle {
        font-size: var(--h3-font-size);
        font-weight: normal;
        color: var(--text-color-light);
    }

    &__price {
        font-size: var(--normal-font-size);
        font-weight: 700;
    }
}