*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: var(--trans-length); // animation for dark mode
}

h1, h2, h3 {
  color: var(--text-color);
  font-weight: var(--font-bold);
  line-height: 1.5;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}