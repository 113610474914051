.safety {
    &__container {
        gap: 2.5rem;
    }
    &__description {
        text-align: center;
    }
    &__link {
        color: var(--text-color-light);
        font-style: italic;
    }
}