.home {
    &__title {
        color: var(--title-color);
        font-size: var(--large-font-size);
        font-weight: var(--font-bold);
        margin-bottom: var(--mb-0-75);
    }
    &__description {
        margin-bottom: var(--mb-2);
    }
}