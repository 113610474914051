.button {
  display: inline-block;
  background-color: var(--primary-color);
  color: black;
  border-radius: 3rem;
  font-weight: var(--font-bold);
  padding: 0.75rem 1.5rem;
  transition: var(--trans-length-short);

  &__link {
    min-width: 9rem;
    text-align: center;
  }

  &__active {
    background-color: var(--container-color);
    border: 2px solid var(--primary-color);
    color: var(--text-color);

    &:hover {
      color: initial;
    }
  }

  &:hover {
    background-color: var(--secondary-color);
  }

  &__header {
    display: none;
  }

  &__cta {
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
  }

  &__icon {
    font-size: var(--h3-font-size);
  }
}